<template>
  <section>
    <FormulateForm
      ref="formStockMovement"
      name="formStockMovement"
    >
      <b-card-actions
        :title="$t('Dados da Movimentação')"
        no-actions
        :show-loading="busy"
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="movementType-radioButton"
              v-model="movementStockForm.movementType"
              value="Output"
              name="type"
              type="radio"
              :options="{ Input: $t('Input'), Output: $t('Output') }"
              :label="$t('Tipo')"
              :element-class="['d-flex', 'mt-1']"
            />
          </b-col>

          <b-col v-if="isTransferenceMovement">
            <FormulateInput
              v-model="movementStockForm.originStock"
              type="vue-select"
              name="originStock"
              :label="$t('Estoque de Origem')"
              :placeholder="$t('Selecione')"
              :options="storeOptions"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="storeId"
              v-model="movementStockForm.storeId"
              type="vue-select"
              class="required"
              validation="required"
              :label="$t('Loja')"
              :placeholder="$t('Selecione')"
              :options="storeOptions"
            />
          </b-col>
          <b-col md="4">
            <e-search-sku
              id="movementStock-product"
              v-model="movementStockForm.skuId"
              :required="true"
              :only-active="true"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="quantity"
              v-model="movementStockForm.stockMovement"
              type="text-number"
              :label="$t('Quantidade')"
              class="required"
              validation="required"
              :precision="3"
              :title="$t('Clique para editar')"
            />
          </b-col>
          <b-col md="5">
            <FormulateInput
              id="movementStock-reason"
              v-model="movementStockForm.movementReasonId"
              type="select-with-button"
              :label="$t('Motivo')"
              :button-permission="$can('Create', 'StockMovementReason')"
              :placeholder="$t('Selecione')"
              :options="getComboReasons"
              @click="showReasonSidebar"
            />
          </b-col>
          <b-col
            v-if="isOutputMovement"
            md="2"
          >
            <FormulateInput
              id="lossesAndDamages"
              v-model="movementStockForm.lossesAndDamages"
              :label="$t('Perdas e Avarias?')"
              type="switch"
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              v-model="movementStockForm.comments"
              name="observation"
              :label="$t('Observações')"
              type="textarea"
              rows="2"
              class="required"
              validation="^required|min:25,length"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <e-crud-actions
        :busy="busy"
        @save="saveMovement"
        @cancel="cancel"
      />
    </FormulateForm>

    <stock-movement-reason-sidebar
      ref="reasonSidebar"
      @after-save="fetchReasons"
    />
  </section>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { accountTypes, averagePriceDomains, paymentTypes } from '@/mixins'
import ECrudActions from '@/views/components/ECrudActions.vue'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import StockMovementReasonSidebar from '../../settings/stock/StockMovementReasonSidebar.vue'

export default {
  name: 'StockMovementMaintain',
  components: {
    BCardActions,
    BCol,
    BRow,
    ECrudActions,
    ESearchSku,
    StockMovementReasonSidebar,
  },

  mixins: [paymentTypes, accountTypes, averagePriceDomains],

  data() {
    return {
      busy: false,
    }
  },

  computed: {
    ...mapState('pages/stock/stockMovement/stockMovementMaintain', ['movementStockForm']),
    ...mapGetters('app', ['storeOptions']),
    ...mapGetters('common/reasons', ['getComboReasons']),
    ...mapGetters('pages/stock/stockMovement', ['stockMovementTypes']),

    isInputMovement() {
      return this.movementStockForm.movementType === 'Input'
    },
    isOutputMovement() {
      return this.movementStockForm.movementType === 'Output'
    },
    isTransferenceMovement() {
      return this.movementStockForm.movementType === 'Transference'
    },
  },

  mounted() {
    this.fetchReasons()
  },

  methods: {
    ...mapActions('common/reasons', ['fetchReasons']),
    ...mapActions('pages/stock/stockMovement/stockMovementMaintain', {
      stSaveMovement: 'saveMovement',
      stCleanState: 'cleanState',
    }),

    async saveMovement() {
      this.$refs.formStockMovement.showErrors()
      if (this.$refs.formStockMovement.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.busy = true
        await this.stSaveMovement()
        this.stCleanState()
        this.showSuccess({ message: 'Movimentação adicionada' })
        this.$router.push({ name: 'stock-movement-list' })
      } catch (error) {
        if (error.response?.data?.Message) {
          await this.mxAlertNotFoundAverageCost(error.response?.data?.Message[0])
        } else {
          this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
        }
      } finally {
        this.busy = false
      }
    },

    cancel() {
      this.stCleanState()
      this.$router.push({ name: 'stock-movement-list' })
    },

    showReasonSidebar() {
      this.$refs.reasonSidebar.show()
    },
  },
}
</script>
