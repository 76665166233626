<template>
  <e-sidebar
    :title="isEdit ? $t('Editar motivo') : $t('Novo motivo')"
    :show="showSidebar"
    :fetching="loading"
    :saving="saving"
    width="500px"
    @save="saveReason"
    @hidden="showSidebar = false"
  >
    <template #content>
      <FormulateForm
        ref="formReason"
        name="formReason"
      >
        <b-row>
          <b-col>
            <FormulateInput
              id="payment-type-name"
              v-model="formReason.name"
              :label="$t('Nome')"
              type="text"
              class="required"
              validation="required"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="payment-type-description"
              v-model="formReason.description"
              :label="$t('Descrição')"
              type="textarea"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { alerts } from '@/mixins'
import ESidebar from '@/views/components/ESidebar.vue'

const getInitialForm = () => ({
  id: null,
  name: '',
  description: '',
})

export default {
  components: { BRow, BCol, ESidebar },

  mixins: [alerts],

  data() {
    return {
      showSidebar: false,
      saving: false,
      loading: false,
      formReason: getInitialForm(),
    }
  },

  computed: {
    isEdit() {
      return !!this.formReason.id
    },
  },

  methods: {
    async show(idReason = null) {
      try {
        this.formReason = getInitialForm()
        this.showSidebar = true

        if (idReason) {
          this.loading = true
          const { data } = await this.$http.get(`/api/stock-movement-reasons/${idReason}`)
          this.formReason = data
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },

    async saveReason() {
      this.$refs.formReason.showErrors()
      if (this.$refs.formReason.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.saving = true

        const { id, name, description } = this.formReason

        const body = {
          id,
          name,
          description,
        }

        await this.$http({
          url: '/api/stock-movement-reasons',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })

        this.$emit('after-save')
        this.showSidebar = false
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>
