var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      title: _vm.isEdit ? _vm.$t("Editar motivo") : _vm.$t("Novo motivo"),
      show: _vm.showSidebar,
      fetching: _vm.loading,
      saving: _vm.saving,
      width: "500px",
    },
    on: {
      save: _vm.saveReason,
      hidden: function ($event) {
        _vm.showSidebar = false
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              { ref: "formReason", attrs: { name: "formReason" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "payment-type-name",
                            label: _vm.$t("Nome"),
                            type: "text",
                            validation: "required",
                          },
                          model: {
                            value: _vm.formReason.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formReason, "name", $$v)
                            },
                            expression: "formReason.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "payment-type-description",
                            label: _vm.$t("Descrição"),
                            type: "textarea",
                          },
                          model: {
                            value: _vm.formReason.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formReason, "description", $$v)
                            },
                            expression: "formReason.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }