var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { ref: "formStockMovement", attrs: { name: "formStockMovement" } },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                title: _vm.$t("Dados da Movimentação"),
                "no-actions": "",
                "show-loading": _vm.busy,
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "movementType-radioButton",
                          value: "Output",
                          name: "type",
                          type: "radio",
                          options: {
                            Input: _vm.$t("Input"),
                            Output: _vm.$t("Output"),
                          },
                          label: _vm.$t("Tipo"),
                          "element-class": ["d-flex", "mt-1"],
                        },
                        model: {
                          value: _vm.movementStockForm.movementType,
                          callback: function ($$v) {
                            _vm.$set(_vm.movementStockForm, "movementType", $$v)
                          },
                          expression: "movementStockForm.movementType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isTransferenceMovement
                    ? _c(
                        "b-col",
                        [
                          _c("FormulateInput", {
                            attrs: {
                              type: "vue-select",
                              name: "originStock",
                              label: _vm.$t("Estoque de Origem"),
                              placeholder: _vm.$t("Selecione"),
                              options: _vm.storeOptions,
                            },
                            model: {
                              value: _vm.movementStockForm.originStock,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.movementStockForm,
                                  "originStock",
                                  $$v
                                )
                              },
                              expression: "movementStockForm.originStock",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "storeId",
                          type: "vue-select",
                          validation: "required",
                          label: _vm.$t("Loja"),
                          placeholder: _vm.$t("Selecione"),
                          options: _vm.storeOptions,
                        },
                        model: {
                          value: _vm.movementStockForm.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.movementStockForm, "storeId", $$v)
                          },
                          expression: "movementStockForm.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-search-sku", {
                        attrs: {
                          id: "movementStock-product",
                          required: true,
                          "only-active": true,
                        },
                        model: {
                          value: _vm.movementStockForm.skuId,
                          callback: function ($$v) {
                            _vm.$set(_vm.movementStockForm, "skuId", $$v)
                          },
                          expression: "movementStockForm.skuId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "quantity",
                          type: "text-number",
                          label: _vm.$t("Quantidade"),
                          validation: "required",
                          precision: 3,
                          title: _vm.$t("Clique para editar"),
                        },
                        model: {
                          value: _vm.movementStockForm.stockMovement,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.movementStockForm,
                              "stockMovement",
                              $$v
                            )
                          },
                          expression: "movementStockForm.stockMovement",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "5" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "movementStock-reason",
                          type: "select-with-button",
                          label: _vm.$t("Motivo"),
                          "button-permission": _vm.$can(
                            "Create",
                            "StockMovementReason"
                          ),
                          placeholder: _vm.$t("Selecione"),
                          options: _vm.getComboReasons,
                        },
                        on: { click: _vm.showReasonSidebar },
                        model: {
                          value: _vm.movementStockForm.movementReasonId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.movementStockForm,
                              "movementReasonId",
                              $$v
                            )
                          },
                          expression: "movementStockForm.movementReasonId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isOutputMovement
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "lossesAndDamages",
                              label: _vm.$t("Perdas e Avarias?"),
                              type: "switch",
                            },
                            model: {
                              value: _vm.movementStockForm.lossesAndDamages,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.movementStockForm,
                                  "lossesAndDamages",
                                  $$v
                                )
                              },
                              expression: "movementStockForm.lossesAndDamages",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          name: "observation",
                          label: _vm.$t("Observações"),
                          type: "textarea",
                          rows: "2",
                          validation: "^required|min:25,length",
                        },
                        model: {
                          value: _vm.movementStockForm.comments,
                          callback: function ($$v) {
                            _vm.$set(_vm.movementStockForm, "comments", $$v)
                          },
                          expression: "movementStockForm.comments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("e-crud-actions", {
            attrs: { busy: _vm.busy },
            on: { save: _vm.saveMovement, cancel: _vm.cancel },
          }),
        ],
        1
      ),
      _c("stock-movement-reason-sidebar", {
        ref: "reasonSidebar",
        on: { "after-save": _vm.fetchReasons },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }